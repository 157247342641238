import React, { useState, useEffect, memo } from "react";
import { Table, Typography } from "antd";
import { AppstoreOutlined, TagOutlined } from "@ant-design/icons";
import { conversationList } from "../../services/conversationService";
import '../../reducers/dashboardReducer';
import { useSelector } from "react-redux";
// import Highlighter from 'react-highlight-words';
import useTableColumnSearch from "../../utils/useTableColumnSearch";
import ListView from "../../components/common/ListView";
import ChatPopUp from "../../components/conversation/ChatPopUp";
import TabsCard from "../../components/common/TabsCard";

const { Title, Link } = Typography;

const ConversationTable = memo(() => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [isChatModalVisible, setIsChatModalVisible] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [keyTab, setKeyTab] = useState('1');

  const { screenSize } = useSelector(({ dashboardReducer }) => dashboardReducer);
  const { getColumnSearchProps } = useTableColumnSearch();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const conversationData = await conversationList();

      // Use a Map to track existing chats for quick lookup
      const existingChats = new Map();
      const flattenedData = [];

      for (const conversation of conversationData) {
        const { userId, messages } = conversation;

        for (const message of messages) {
          flattenedData.push({
            ...message,
            user: userId,
            date: new Date(message.repliedTimestamp),
            createdTimestamp: new Date(message.createdTimestamp)
          });
        }
      }
      flattenedData.sort((a, b) => b.createdTimestamp - a.createdTimestamp);

      const newData = [];
      for (let i = 0; i < flattenedData.length; i++) {
        const item = flattenedData[i];

        if (existingChats.has(item._id)) continue;

        const chat = [{
          query: item.originalQuery,
          resp: item.ai,
          id: item._id,
          type: item.type
        }];

        let count = i + 1;
        while (count < flattenedData.length && flattenedData[count].type === 'DM') {
          const nextItem = flattenedData[count];
          chat.push({
            query: nextItem.originalQuery,
            resp: nextItem.ai,
            id: nextItem._id,
            type: nextItem.type
          });
          existingChats.set(nextItem._id, true);
          count++;
        }

        newData.push({ ...item, chat });
        existingChats.set(item._id, true);
      }

      setData(flattenedData);
      setData2(newData);

    } catch (error) {
      console.error("Error fetching conversation data:", error);
    }
  };


  const handleUserClick = (record) => {
    setSelectedConversation(record);
    setIsChatModalVisible(true);
  };

  const handleModalClose = () => {
    setIsChatModalVisible(false);
    setSelectedConversation(null);
  };

  // const showModal = (messages, userName) => {
  //   setModalContent(messages);
  //   setIsModalVisible(true);
  //   setInstaName(userName);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  const columnsGrouped = [
    {
      title: "User Name",
      dataIndex: "user",
      key: "user",
      render: (user, record) => (
        <Link
          onClick={() => handleUserClick(record)}
          style={{ color: '#1890ff', cursor: 'pointer' }}
        >
          {user}
        </Link>
      ),
    },
    {
      title: "Feeling",
      dataIndex: "feeling",
      key: "feeling",
      ...getColumnSearchProps('feeling'),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        });
      }
    },
  ];


  const columns = [
    {
      title: "User Name",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps('user'),
    },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   render: (type) => (
    //     <Tag key={type} style={{ marginRight: '8px', padding: '2px 8px' }}>
    //       {type}
    //     </Tag>
    //   ),
    // },
    {
      title: "Feeling",
      dataIndex: "feeling",
      key: "feeling",
      ...getColumnSearchProps('feeling'),
    },
    {
      title: "Query",
      dataIndex: "originalQuery",
      key: "originalQuery",
      width: '30%',
      ...getColumnSearchProps('originalQuery'),
    },
    {
      title: "Response",
      dataIndex: "ai",
      key: "ai",
      width: '30%',
      ...getColumnSearchProps('ai'),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: '20%',
      render: (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit'
        });
      }
    },
  ];

  const tabLabelIcons = [
    {
      id: '1',
      name: "Detailed",
      icon: <TagOutlined />,
      component: <Table columns={columns} dataSource={data} rowKey="_id" />
    },
    {
      id: '2',
      name: "Grouped",
      icon: <AppstoreOutlined />,
      component: <Table columns={columnsGrouped} dataSource={data2} rowKey="_id" />
    },
  ];

  const handleTabKey = (key) => {
    setKeyTab(key);
  }

  return (
    <div>

      {screenSize < 576 ? <ListView
        dataSource={data}
        title={"User Id"}
        description={"Feeling"}
        extraButtonDesc={"Know More"}
        fromPage={"conversation"}
        searchArea={true}
        mainTitle={"Conversations"}
      /> : <>
        <Title level={2} style={{ textAlign: "center", marginBottom: "20px" }}>
          Conversation
        </Title>
        <TabsCard
          activeKey={keyTab}
          handleTabKey={handleTabKey}
          tabLabelIcons={tabLabelIcons}
        // tabBarExtraContent={{
        //   right: (
        //     <div style={{
        //       // width: '120px',
        //       textAlign: 'right'
        //       // display: "flex",
        //       // justifyContent: "space-between",
        //       // marginLeft:  '-10%'
        //     }}>
        //       <Button type="primary" onClick={() => fetchData(true)}>
        //         <ReloadOutlined />
        //       </Button>
        //     </div>
        //   )
        // }}
        />
      </>}

      {selectedConversation && (
        <ChatPopUp
          visible={isChatModalVisible}
          onClose={handleModalClose}
          data={selectedConversation}
        />
      )}
    </div>
  );
});

export default ConversationTable;